import {
  REVIEW_LIST_FAIL,
  REVIEW_LIST_REQUEST,
  REVIEW_LIST_SUCCESS,
} from "../constants/reviewConstants";

//REVIEW LIST REDUCER
export const reviewListReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case REVIEW_LIST_REQUEST:
      return { loading: true, reviews: [] };
    case REVIEW_LIST_SUCCESS:
      return {
        loading: false,
        reviews: action.payload.reviews,
        //   pages: action.payload.pages,
        //   page: action.payload.page,
      };
    case REVIEW_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
