import {
  RECOMMENDED_RESTAURANTS_LIST_FAIL,
  RECOMMENDED_RESTAURANTS_LIST_REQUEST,
  RECOMMENDED_RESTAURANTS_LIST_SUCCESS,
  TRENDING_RESTAURANTS_LIST_FAIL,
  TRENDING_RESTAURANTS_LIST_REQUEST,
  TRENDING_RESTAURANTS_LIST_SUCCESS,
  ALL_RESTAURANTS_LIST_SUCCESS,
  ALL_RESTAURANTS_LIST_FAIL,
  ALL_RESTAURANTS_LIST_REQUEST,
} from "../constants/restaurantConstants";

//TRENDING RESTAURANT REDUCER
export const trendingRestaurantListReducer = (
  state = { restaurants: [] },
  action
) => {
  switch (action.type) {
    case TRENDING_RESTAURANTS_LIST_REQUEST:
      return { loading: true, restaurants: [] };
    case TRENDING_RESTAURANTS_LIST_SUCCESS:
      return {
        loading: false,
        restaurants: action.payload.restaurants,
        //   pages: action.payload.pages,
        //   page: action.payload.page,
      };
    case TRENDING_RESTAURANTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//RECOMMENDED RESTAURANT REDUCER
export const recommendedRestaurantListReducer = (
  state = { restaurants: [] },
  action
) => {
  switch (action.type) {
    case RECOMMENDED_RESTAURANTS_LIST_REQUEST:
      return { loading: true, restaurants: [] };
    case RECOMMENDED_RESTAURANTS_LIST_SUCCESS:
      return {
        loading: false,
        restaurants: action.payload.restaurants,
        //   pages: action.payload.pages,
        //   page: action.payload.page,
      };
    case RECOMMENDED_RESTAURANTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//ALL RESTAURANT REDUCER
export const allRestaurantListReducer = (
  state = { restaurants: [] },
  action
) => {
  switch (action.type) {
    case ALL_RESTAURANTS_LIST_REQUEST:
      return { loading: true, restaurants: [] };
    case ALL_RESTAURANTS_LIST_SUCCESS:
      return {
        loading: false,
        restaurants: action.payload.restaurants,
        //   pages: action.payload.pages,
        //   page: action.payload.page,
      };
    case ALL_RESTAURANTS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
