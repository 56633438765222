// APP IMPORTS
import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";

import "aos/dist/aos.css";
import LoaderOverlay from "./shared/components/Loaders/LoaderOverlay";
import PageNotFound from "./pages/page-not-found/PageNotFound";

// LAZY LOADING FOR PAGES
const LazySignIn = React.lazy(() => import("./pages/auth/SignIn/SignIn"));
const LazySignUp = React.lazy(() => import("./pages/auth/SignUp/SignUp"));
const LazyHome = React.lazy(() => import("./pages/home/pages/Home"));
const LazyProfile = React.lazy(() =>
  import("./pages/my-profile/pages/Profile")
);
const LazyPrivacyPolicy = React.lazy(() =>
  import("./pages/privacy-policy/PrivacyPolicy")
);

const LazyAbout = React.lazy(() => import("./pages/about/pages/About"));
const LazyCareer = React.lazy(() => import("./pages/career/pages/Career"));
const LazyMyReservations = React.lazy(() =>
  import("./pages/my-reservations/pages/MyReservations")
);
const LazyReserveIt = React.lazy(() =>
  import("./pages/home/pages/reserve-it/ReserveItPage")
);

const LazyExperienceIt = React.lazy(() =>
  import("./pages/home/pages/experience-it/ExperienceIt")
);
const LazyOnboarding = React.lazy(() =>
  import("./pages/onboarding/pages/venue-onboarding/Onboarding")
);
const LazySearchPage = React.lazy(() =>
  import("./pages/search/pages/SearchPage")
);

const LazyUserPage = React.lazy(() => import("./pages/user/UserProfile"));
const LazyRestaurantDetails = React.lazy(() =>
  import("./pages/restaurant/pages/RestaurantDetails")
);
const LazyWelcomePage = React.lazy(() =>
  import("./pages/welcome/pages/Welcome")
);

const LazyVerifyEmail = React.lazy(() =>
  import("./pages/auth/OTP/VerifyEmail")
);

const LazySupport = React.lazy(() => import("./pages/support/Support"));

const LazyFeedback = React.lazy(() => import("./pages/feedback/Feedback"));

function App() {
  // EFFECT FOR AOS ANIMATIONS
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          {/* Onboarding routes*/}

          {/* SIGN UP */}
          <Route
            path="/"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazySignUp />
              </Suspense>
            }
          />

          {/* SIGN IN */}
          <Route
            path="/signin"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazySignIn />
              </Suspense>
            }
          />

          {/* VERIFY EMAIL */}
          <Route
            path="/verify"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyVerifyEmail />
              </Suspense>
            }
          />

          {/* HOME PAGE */}
          <Route
            path="/home"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyHome />
              </Suspense>
            }
          />

          {/* ABOUT PAGE */}
          <Route
            path="/about"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyAbout />
              </Suspense>
            }
          />

          {/* VENUE DETAILS */}
          <Route
            path={`/restaurants/:id/details`}
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyRestaurantDetails />
              </Suspense>
            }
          />

          {/* CAREER PAGE */}
          <Route
            path="/career"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyCareer />
              </Suspense>
            }
          />

          {/* SEARCH PAGE */}
          <Route
            path="/search"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazySearchPage />
              </Suspense>
            }
          />

          {/* WELCOME PAGE */}
          <Route
            path="/welcome"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyWelcomePage />
              </Suspense>
            }
          />

          {/* USER PAGE */}
          <Route
            path="/user/:id"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyUserPage />
              </Suspense>
            }
          />

          {/* ONBOARDING */}
          <Route
            // path="/join"
            path="/a2d5b90f-201d-5eeff-brzf-ed71738e122d/join"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyOnboarding />
              </Suspense>
            }
          />

          {/* RESERVE IT */}
          <Route
            path="/reserve-it"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyReserveIt />
              </Suspense>
            }
          />

          {/* EXPERIENCE IT */}
          <Route
            path="/experience-it"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyExperienceIt />
              </Suspense>
            }
          />

          {/* MY RESERVATIONS */}
          <Route
            path="/my-reservations/:id"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyMyReservations />
              </Suspense>
            }
          />

          {/* MY PROFILE */}
          <Route
            path="/profile"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyProfile />
              </Suspense>
            }
          />

          {/* PRIVACY POLICY */}
          <Route
            path="/privacy-policy"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyPrivacyPolicy />
              </Suspense>
            }
          />

          {/* SUPPORT */}
          <Route
            path="/support"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazySupport />
              </Suspense>
            }
          />

          {/* FEEDBACK */}
          <Route
            path="/feedback"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyFeedback />
              </Suspense>
            }
          />

          {/* VERIFY EMAIL */}
          <Route
            path="/verify-email"
            element={
              <Suspense fallback={<LoaderOverlay />}>
                <LazyVerifyEmail />
              </Suspense>
            }
          />

          {/*PAGE NOT FOUND*/}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
