export const TRENDING_RESTAURANTS_LIST_REQUEST =
  "TRENDING_RESTAURANTS_LIST_REQUEST";
export const TRENDING_RESTAURANTS_LIST_SUCCESS =
  "TRENDING_RESTAURANTS_LIST_SUCCESS";
export const TRENDING_RESTAURANTS_LIST_FAIL = "TRENDING_RESTAURANTS_LIST_FAIL";

export const RECOMMENDED_RESTAURANTS_LIST_REQUEST =
  "RECOMMENDED_RESTAURANTS_LIST_REQUEST";
export const RECOMMENDED_RESTAURANTS_LIST_SUCCESS =
  "RECOMMENDED_RESTAURANTS_LIST_SUCCESS";
export const RECOMMENDED_RESTAURANTS_LIST_FAIL =
  "RECOMMENDED_RESTAURANTS_LIST_FAIL";

export const ALL_RESTAURANTS_LIST_REQUEST = "ALL_RESTAURANTS_LIST_REQUEST";
export const ALL_RESTAURANTS_LIST_SUCCESS = "ALL_RESTAURANTS_LIST_SUCCESS";
export const ALL_RESTAURANTS_LIST_FAIL = "ALL_RESTAURANTS_LIST_FAIL";
