import {
  SLOT_LIST_FAIL,
  SLOT_LIST_REQUEST,
  SLOT_LIST_SUCCESS,
} from "../constants/slotConstants";

//REVIEW LIST REDUCER
export const slotListReducer = (state = { slots: [] }, action) => {
  switch (action.type) {
    case SLOT_LIST_REQUEST:
      return { loading: true, slots: [] };
    case SLOT_LIST_SUCCESS:
      return {
        loading: false,
        slots: action.payload.slots,
        //   pages: action.payload.pages,
        //   page: action.payload.page,
      };
    case SLOT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
