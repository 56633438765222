import React from "react";
import "./style/LoaderOverlay.css";
import LoaderIcon from "./LoaderIcon";
import rit from "../../../assets/rit-related/rit.png";

function LoaderOverlay() {
  return (
    <div className="loader-overlay">
      <div>
        <div className="isidora-bold rit-logo-loading">
          <img src={rit} alt="rit" />
        </div>

        <div className="loader-icon">
          <LoaderIcon type="ball-spin-fade-loader" color="var(--rit-green)" />
        </div>
      </div>
    </div>
  );
}

export default LoaderOverlay;
