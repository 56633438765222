import React from "react";
import pagenotfound from "../../assets/gifs/pageNotFound.gif";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <div className="pnf page">
      <div>
        <div>
          <img src={pagenotfound} alt="Page not found" className="pnf-gif" />
        </div>

        <div className="isidora-bold pnf-title">
          Uh-oh... the page does not exist.
        </div>
        <div className="pnf-description">
          Double check the URL and try again.
        </div>

        <div className="isidora-bold pnf-logo">Rit.</div>
      </div>
    </div>
  );
}

export default PageNotFound;
